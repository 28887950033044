/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import "../../scss/styles.scss";
import { Helmet } from "react-helmet"

function SEO({ meta }) {
  const data = useStaticQuery(graphql`
    query SiteMetadataQuery {
      site: site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);
  const {title,description,siteUrl}=data.site.siteMetadata;
  
  //console.log(meta)
  
  
    const seo = {
      site_name:title,
      title : meta.title || title,
      description : meta.description || description,
      siteURL : siteUrl,
      imgURL : meta.image.childImageSharp.fixed.src || "",
      imgWidth :  meta.image.childImageSharp.fixed.width || "",
      imgHeight :  meta.image.childImageSharp.fixed.height || "",
      pageUrl : meta.url,
    }
  //console.log("site description: ",seo.description)
  return ( 
  <>
    <Helmet defer={false} defaultTitle={seo.title} >
      <html lang="fr" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <link rel="canonical" href={`${seo.siteURL}/${seo.pageUrl}`}/>
    </Helmet>
    <MetaOG seo = {seo}/>
    
  </>)
}

const MetaOG = ({seo})=>{
  return(
    <>
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:site_name" content={seo.site_name} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image:url" content={`${seo.siteURL}${seo.imgURL}`}/>
      <meta property="og:image:width" content={seo.imgWidth} />
      <meta property="og:image:height" content={seo.imgHeight} />
    </>
  )
}



SEO.defaultProps = {
  lang: `fr`,
  meta: PropTypes.shape({
    title: '',
    description: '',
    url: '',
    image : PropTypes.shape({
      publicURL : '',
      childImageSharp : PropTypes.shape({
        fixed : PropTypes.shape({
        width : '',
        height : '',
        src : '',
        }),
      }),
    }),
  }),
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    image : PropTypes.shape({
      publicURL : PropTypes.string,
      childImageSharp : PropTypes.shape({
        fixed : PropTypes.shape({
        width : PropTypes.number,
        height : PropTypes.number,
        src : PropTypes.string,
        }),
      }),
    }),
  }),
  title: PropTypes.string
};

export default SEO;
